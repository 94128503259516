var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-navigation-drawer',{staticStyle:{"top":"0px","max-height":"100vh!important"},attrs:{"right":"","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_c('filterSales',{attrs:{"company":_vm.company},on:{"filtersUtility":_vm.filtersUtility}})],1),_c('totals',{attrs:{"totals":_vm.totals}}),_c('v-toolbar',{staticClass:"px-4 pt-3",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Utilidades "),_c('br'),(_vm.filterStorage())?_c('span',{staticStyle:{"font-size":"12px","color":"grey","position":"absolute"}},[_vm._v("Últimos 30 días")]):_vm._e()]),_c('v-btn',{staticClass:"ml-6 pt-2",attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.openFilter()}}},[_vm._v("mdi-filter")])],1),_c('v-spacer'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('download')),expression:"permissions('download')"}],attrs:{"icon":""}},[_c('v-icon',{on:{"click":_vm.exportExcel}},[_vm._v("mdi-download")])],1)],1),(_vm.showTable && _vm.tableGraph)?_c('v-data-table',{staticClass:"elevation-0 px-6 py-4",attrs:{"height":"600","fixed-header":"","footer-props":{'items-per-page-options':[15, 30, 50, 100]},"headers":_vm.headers,"items":_vm.sales,"options":_vm.options,"server-items-length":_vm.totalSales,"loading":_vm.loading,"item-class":_vm.itemRowBackground},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.printed",fn:function(ref){
var item = ref.item;
return [(item.printed!=true)?_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-close ")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-check ")])]}},{key:"no-data",fn:function(){return [_vm._v(" No existen registros de ventas aún ")]},proxy:true},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",staticStyle:{"min-height":"0px!important","font-size":"14px"},attrs:{"to":{ path: '/clients/client/'+ item.companyID}}},[_vm._v(_vm._s(item.company))])]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.cost*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.price*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.value*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.utility",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.utility*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"font-size":"13px"}},[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('editSales')),expression:"permissions('editSales')"}],on:{"click":function($event){return _vm.editItem(item.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Editar ")],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('deleteSales')),expression:"permissions('deleteSales')"}],on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Eliminar ")],1)],1)],1)]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }